import { UserContext } from 'Components/Context/UserContext';
import React, { useContext } from 'react';
import { i18n } from 'utils/i18n/index'
interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
    const {logout} = useContext(UserContext);
    return (
      <div>
        {i18n.t("Logout")}
        {logout()}
      </div>
    );
};

export default Logout;
