import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import history from 'utils/history';

const exactWhitelist = ['/'];
const whitelist = [] as string[];

export const IdleTimer = () => {
  const isWhitelisted = useRouteMatch({ path: whitelist });
  const isExactWhitelisted = useRouteMatch({
    path: exactWhitelist,
    exact: true,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const goBackToHome = () => {
      history.push('/logout');
    };

    const restartAutoReset = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        !timeout || clearTimeout(timeout);
        window.removeEventListener('mousemove', restartAutoReset);
        window.removeEventListener('touchmove', restartAutoReset);
        window.removeEventListener('keydown', restartAutoReset);
        goBackToHome();
      }, 1000 * 60 * 30);
    };

    if (isWhitelisted || isExactWhitelisted) {
      !timeout || clearTimeout(timeout);
      window.removeEventListener('mousemove', restartAutoReset);
      window.removeEventListener('touchmove', restartAutoReset);
      window.removeEventListener('keydown', restartAutoReset);
      return;
    }

    restartAutoReset();

    window.addEventListener('keydown', restartAutoReset);
    window.addEventListener('mousemove', restartAutoReset);
    window.addEventListener('touchmove', restartAutoReset);
  }, [isWhitelisted, isExactWhitelisted]);

  return <div />;
};
