import * as yup from 'yup';
import { ptShort } from 'yup-locale-pt';

yup.setLocale(ptShort);

export const RequetPurchaseSchema = yup
  .object()
  .shape({
    quantity: yup.number().min(1).required(),
    observation: yup.string().max(300),
  })
  .noUnknown(true);
