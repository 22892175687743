import React, { useState, useEffect } from 'react';

import { listPurchase, PurchaseModel } from '../../utils/api/purchase';

import Navbar from '../../Components/Navbar';
import AutoMargin from 'Components/AutoMargin';
import { i18n } from 'utils/i18n/index';

interface PurchaseProps {}
const Purchases: React.FC<PurchaseProps> = () => {
  const [purchases, setPurchases] = useState<PurchaseModel[] | undefined>();

  useEffect(() => {
    (async () => {
      const purchases = await listPurchase();
      setPurchases(purchases);
    })();
  }, []);

  const translateStatus = (status: string) => {
    switch (status) {
      case 'pending':
        return i18n.t('pending');
      case 'accepted':
        return i18n.t('accepted');
      case 'completed':
        return i18n.t('accepted');
    }
  };

  return (
    <div>
      <Navbar />
      <AutoMargin className="w-full mt-4 p-2 lg:mt-7 max-w-7xl">
        <p className="text-xs">
          DASHBOARD / {i18n.t('storeMenu')} /{' '}
          <span className="text-primary">{i18n.t('myExchangesTitle')}</span>
        </p>
        <p className="text-xl lg:text-3xl mt-4 text-black">
          {i18n.t('myExchanges')}
        </p>
        <div className="mt-8 lg:mt-16 shadow rounded p-4 lg:p-7 mb-5">
          {!purchases ? (
            <p>{i18n.t('loading')}</p>
          ) : !purchases.length ? (
            <p className="ml-2">{i18n.t('noExchangeScore')}</p>
          ) : (
            <div className="mt-3">
              {purchases.map((product) => (
                <div className="mt-3">
                  <p className="text-lg font-medium text-black">{`${product.product.name}`}</p>
                  <p>{`${i18n.t('quantity')}: ${product.quantity}`}</p>
                  <p>{`${i18n.t('meritusSpent')}: ${product.meritus}`}</p>
                  <p>
                    {product?.additionalInformation?.observation
                      ? `${i18n.t('observation')} ${
                          product.additionalInformation.observation
                        }`
                      : ''}
                  </p>
                  <p>{`${i18n.t('date')} ${new Date(
                    product.createdAt
                  ).toLocaleString(i18n.t('dateLocaleString'), {
                    dateStyle: 'medium',
                    hour12: false,
                    timeStyle: 'short',
                  })}`}</p>
                  {/* <p>
                    {product?.additionalInformation?.observation
                      ? `${i18n.t("observation")} ${product.additionalInformation.observation}`
                      : ''}
                    </p>*/}
                  <p>{`${i18n.t('status')} ${translateStatus(
                    product.status
                  )}`}</p>
                  <hr className="mt-3 border-lighter-70" />
                </div>
              ))}
            </div>
          )}
        </div>
      </AutoMargin>
    </div>
  );
};

export default Purchases;
