// import { getToken } from 'utils/jwt';
import api from './axios';


export type TermsAndCondtionsModel = {
    id: string;
    name: string;
    version: number;
    createdAt: Date;
    link: string;
  } 

export const getLatestTerm = async () => {
  const { data } = await api.request({
    method: 'get',
    url: `/termsAndConditions/latest`,
  });
  return data as TermsAndCondtionsModel || null || undefined;
};

export const acceptTerm = async (termId: string) => {
  const { data } = await api.request({
    method: 'post',
    url: `/termsAndConditions/userAccept/${termId}`,
  });
  return data as TermsAndCondtionsModel || null || undefined;
};
