import api from './axios';

export type CompanyModel = {
  id: string;
  name: string;
  CNPJ: string;
  groups: Array<Group['id']>;
  mainGroup: Group['id'];
  values: Value[];
  slogan: string,
  mission: string;
  vision: string;
  logo: string;
  desc: string;
  alternativeText: any;
  config?:{
    removeFeedFilter?: boolean
  }
}

type Group = {
  id: string
  name: string;
  users: Array<string>;
}

type Value = {
  icon: string;
  name: string;
  desc: string;
}

export const getCompany = async (companyId: CompanyModel['id']) => {
  const { data } = await api.get<CompanyModel>(`company/${companyId}`, {});
  return data;
}