import { ReactComponent as WalletIcon } from '../assets/vectors/walletIcon.svg';
import { ReactComponent as XIcon } from 'assets/vectors/x.svg';
import { useState, useContext } from 'react';
import lazer from 'assets/images/lazer.png';
import alimentacao from 'assets/images/alimetacao.png';
import escritorio from 'assets/images/escritorio.png';
import bemEstar from 'assets/images/bem-estar.png';
import treinamento from 'assets/images/treinamento.png';
import geral from 'assets/images/geral.png';
import { UserContext } from 'Components/Context/UserContext';
import { i18n } from 'utils/i18n/index';

export function Wallets() {
  const { user } = useContext(UserContext);
  const [showWallets, setShowWallets] = useState(false);

  const ToggleShowWallet = () => {
    setShowWallets(!showWallets);
  };

  const iconSelector: Record<string, string> = {
    Alimentação: alimentacao,
    'Bem-estar': bemEstar,
    Lazer: lazer,
    Escritório: escritorio,
    Treinamento: treinamento,
  };

  return (
    <div className="">
      <div
        className="flex items-center cursor-pointer"
        onClick={ToggleShowWallet}
      >
        <WalletIcon className="w-6 h-7 mr-1 fill-current" />
        <p className="text-sm">{i18n.t("Wallets")}</p>
      </div>
      {showWallets === true && (
        // <div className="absolute bg-white pb-5 py-6 px-5 shadow rounded-input lg:top-12 top-20 mr-1.5 w-64">
        <div className="absolute mt-2 text-darker-90 transform -translate-x-48">
          <div className="bg-white shadow rounded-input w-72 p-5">
            <div className="flex items-center justify-between">
              <p className="text-lg">
                <strong>{i18n.t("Wallets")}:</strong>
              </p>
              <button onClick={ToggleShowWallet}>
                <XIcon className="stroke-current text-darker-50 w-2/3" />
              </button>
            </div>
            <div className="flex flex-col items-stretch mt-5">
              <div className="flex items-end justify-between">
                <div className="flex">
                  <img src={geral} alt="" className="w-6 h-7" />
                  <p className="px-2 mt-2 text-base">{i18n.t("General")}:</p>
                </div>
                <p className="rounded-full bg-primary px-3 py-1 text-xs text-white">
                  {user?.meritus}
                </p>
              </div>
              <div className="flex flex-col items-stretch">
                {user?.wallets?.map((wallet, index) =>
                  wallet.isDisabled !== true || wallet.fund !== 0 ? (
                    <div
                      key={index}
                      className="flex items-center justify-between mt-4"
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <img
                            src={iconSelector[wallet.fromTemplate]}
                            alt=""
                            className="w-6 h-6"
                          />
                        </div>
                        <p className="px-2 mt-2 text-base">{i18n.t(`${wallet.name}`)}</p>
                      </div>
                      <p
                        className={`rounded-full bg-${wallet.style.theme} px-3 py-1 text-xs text-white`}
                      >
                        {wallet.fund}
                      </p>
                    </div>
                  ) : (
                    ''
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
