import { ReactComponent as WorldIcon } from '../assets/vectors/world.svg';
import { useState } from 'react';
import br from 'assets/images/brazilIcon.jpg';
import usa from 'assets/images/usaIcon.jpg';
import es from 'assets/images/spainIcon.jpg';
import { Languages } from 'utils/i18n/index';
export const I18N_STORAGE_KEY = 'i18nextLng';

export const LanguageSwitcher = () => {
  const [active, setMode] = useState(false);
  const ToggleMode = () => {
    setMode(!active);
  };
  const handleSelectChange = (language: Languages) => {
    window.localStorage.setItem(I18N_STORAGE_KEY, language);
    window.location = window.location;
  };

  return (
    <div className="flex flex-col items-end">
      <div onClick={ToggleMode}>
        <WorldIcon className="w-6 h-6 md:w-7 lg:w-8 lg:h-8 cursor-pointer text-darker-50 stroke-current hover:text-black" />
      </div>
      {active === true && (
        <div className="absolute bg-neutral p-5 pr-9 rounded-input shadow top-11">
          <ul className=" text-sm flex flex-col gap-2 text-darker-50">
            <li
              className="cursor-pointer flex flex-row items-center gap-1 hover:text-black"
              onClick={() => handleSelectChange(Languages.PORTUGUESE)}
            >
              <img src={br} alt="" className="w-5" />
              <p>Português</p>
            </li>
            <li
              className="cursor-pointer flex flex-row items-center gap-1 hover:text-black"
              onClick={() => handleSelectChange(Languages.ENGLISH)}
            >
              <img
                src={usa}
                alt=""
                className="mr:1 w-3 h-3 md:w-5 md:h-5 md:mr-1.5 lg:w-5 lg:h-5 lg:mr-1.5"
              />
              <p>English</p>
            </li>
            <li
              className="cursor-pointer flex flex-row items-center gap-1  hover:text-black"
              onClick={() => handleSelectChange(Languages.SPANISH)}
            >
              <img
                src={es}
                alt=""
                className="mr:1 w-3 h-3 md:w-5 md:h-5 md:mr-1.5 lg:w-5 lg:h-5 lg:mr-1.5"
              />
              <p>Español</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
