import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as LogoIcon } from 'assets/vectors/logo.svg';
import AutoMargin from 'Components/AutoMargin';

import { i18n } from 'utils/i18n/index';
import history from 'utils/history';
import {
  acceptTerm,
  getLatestTerm,
  TermsAndCondtionsModel,
} from 'utils/api/termsAndConditions';
import { toast } from 'react-toastify';
import { UserContext } from 'Components/Context/UserContext';

interface TermsAndConditionsProps {}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = () => {
  const [checked, setChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [term, setTerm] = useState<TermsAndCondtionsModel | null | undefined>(
    undefined
  );

  const { acceptTerms } = useContext(UserContext);

  useEffect(() => {
    getLatestTerm().then(setTerm);
  }, []);

  const onSubmit = async () => {
    setIsDisabled(true);

    if (term == null)
      return toast.error(i18n.t('unableAcceptTerms'));

    try {
      await acceptTerm(term.id);
      await acceptTerms();
      history.replace('/colaborador/reconhecimento');
    } catch (error) {
      toast.error(i18n.t('unableAcceptTerms'));
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="min-h-screen">
      <nav
        className={`w-full pt-2 pb-2 mb-2 sticky top-0 z-50 shadow bg-white`}
      >
        <div className="flex justify-center items-center px-3">
          <LogoIcon />
          <p className="text-3xl">meritu</p>
        </div>
      </nav>
      <AutoMargin className="bg-white rounded w-full py-3 lg:p-10 max-w-7xl">
        <p className="text-black text-xl px-2">{i18n.t('termsOfUse')}</p>
        <p className="text-sm px-2">{i18n.t('toAccess')}</p>
        <div className="h-60-screen min-h-full mt-1">
          {term == null ? (
            <p>Loading....</p>
          ) : (
            <iframe
              title="terms"
              src={term.link}
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
            ></iframe>
          )}
        </div>
        <div className="px-2 mt-2">
          <div className="w-full flex-grow-0 flex items-baseline justify-end">
            <input
              type="checkbox"
              name="termsOfUse"
              className="mr-3"
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
            <p>
              <span
                onClick={() => {
                  setChecked(!checked);
                }}
              >
                {i18n.t('haveReadThe')}
              </span>
              <a
                href={term?.link}
                target="_blank"
                rel="noreferrer nofollow"
                className="text-primary-darker"
              >
                {' '}
                {i18n.t('termsOfService')}
              </a>
            </p>
          </div>
          <div className="flex gap-2 lg:gap-4 mx-3 w-full justify-end items-center">
            <button
              className="text-center py-2 px-5 lg:px-14 border box-border border-solid border-black rounded-full disabled:opacity-25"
              type="button"
              onClick={() => history.push('/logout')}
            >
              {i18n.t('cancel')}
            </button>
            <button
              type="submit"
              className="text-center py-2 px-5 lg:px-14 rounded-full bg-primary-darker text-white items-baseline disabled:opacity-30"
              disabled={!checked || isDisabled}
              onClick={onSubmit}
            >
              {i18n.t('accept')}
            </button>
          </div>
        </div>
      </AutoMargin>
    </div>
  );
};

export default TermsAndConditions;
